import { css, useTheme } from '@emotion/react'
import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'

import { numberToDollars } from '../../utils/utils'

import { PiggyBank, Warning, SmileyFace } from '../../assets/icons'
import {
  SVGWrapper,
  Spacer,
  HelpIcon,
  TooltipText,
  FormError,
  SharedModal,
  Loading,
  Button,
} from '../../components'
import { Section } from './styles'
import { planTypeIdToAccountKey } from '../../constants/plan-types'
import dayjs from 'dayjs'
import LumpSumPensionMayBeEligible from './action-items/LumpSumPensionMayBeEligible'

const catchUpTypes = [1, 2] // 401k, 403b

const Intro = ({
  history,
  mode,
  store: {
    person,
    updatePerson,
    accountsBalance,
    standardLimit,
    standardCatchup,
    additionalCatchup,
    getLimits,
    config: {
      spendingMode,
      enableSpendingMode,
      disableSpendingMode,
      enableSpendDownModeling,
      enableCatchupContributions,
      isRetail,
      template,
    },
    selectedInstitutionalPension,
    employerAccounts,
    otherAccounts,
    getAllPensions,
    getSurvivorPensionOptions,
    spendingBaseCases,
    spendingModifiedCases,
    isRequiredToReviewRetirementAccount,
    institutionalAccount,
    institutional,
    account,
    config,
  },
}) => {
  const [state, setState] = useState('idle')
  const [spending, setSpending] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [actionItemsStatus, setActionItemStatus] = useState(['all clear'])
  const theme = useTheme()

  useEffect(() => {
    getLimits()
  }, [getLimits])

  useEffect(() => {
    const filterPensions = async filter => {
      const filteredPensions = await getAllPensions().then(pensions => pensions.filter(filter))
      return filteredPensions
    }
    setSpending(spendingMode)
    filterPensions(
      pension =>
        pension.paymentType === 'L' &&
        pension.lumpsumreceived === 'N' &&
        pension.pensionStartAge <= person.age
    ).then(pensions => {
      if (pensions.length > 0) {
        // if user is templateId 4 (atmos), only show message if they are terminated (1 and 2 are still employed)
        if (config.templateId !== 4)
          setActionItemStatus(prevStatus => [
            ...prevStatus.filter(item => item !== 'all clear'),
            'lumpSumPensionPayoutExpected',
          ])
      } else if (account.employeeStatus !== 1 && account.employeeStatus !== 2) {
        setActionItemStatus(prevStatus => [
          ...prevStatus.filter(item => item !== 'all clear'),
          'lumpSumPensionMayBeEligible',
        ])
      }
    })

    if (isRetail && otherAccounts.length === 0 && employerAccounts.length === 0) {
      setActionItemStatus(prevStatus => [
        ...prevStatus.filter(item => item !== 'all clear'),
        'add at least one account',
      ])
    }
  }, [
    account.employeeStatus,
    config.templateId,
    employerAccounts.length,
    getAllPensions,
    isRetail,
    otherAccounts.length,
    person.age,
    spendingMode,
  ])

  useEffect(() => {
    if (mode === 'retWithinThreeYears' && person.age >= person.retirementAge) {
      setActionItemStatus(prevStatus => [
        ...prevStatus.filter(item => item !== 'all clear'),
        'ageEqualOrGreaterThanRetirement',
      ])
    }
  }, [person, mode])

  useEffect(() => {
    const contributionUpdateRequired = () => {
      const employerAccountsUpdateRequired = employerAccounts.filter(
        account =>
          account.type !== 'Institutional' &&
          account.contributionEligibility &&
          account.contributing
      )

      const otherAccountsUpdateRequired = otherAccounts.filter(account => {
        return account.contributing && !planTypeIdToAccountKey[account.planType]?.includes('ira')
      })

      return employerAccountsUpdateRequired.length > 0 || otherAccountsUpdateRequired.length > 0
    }

    if (mode === 'spending' && person.age > person.retirementAge && contributionUpdateRequired()) {
      setActionItemStatus(prevStatus => [
        ...prevStatus.filter(item => item !== 'all clear'),
        'accountContributionsUpdateRequired',
      ])
    }
  }, [person, mode, employerAccounts, otherAccounts])

  useEffect(() => {
    if (
      mode === 'spending' &&
      _.get(spendingModifiedCases, 'spendingScenario.budget.dollarAmountNeeded', 0) >
        _.get(spendingBaseCases, 'averageMarketSpendingAdvice.monthlyIncome', 0)
    ) {
      setActionItemStatus(prevStatus => [
        ...prevStatus.filter(item => item !== 'all clear'),
        'spendingShortfall',
      ])
    }
  }, [mode, spendingBaseCases, spendingModifiedCases])

  useEffect(() => {
    if (institutionalAccount && isRequiredToReviewRetirementAccount) {
      setActionItemStatus(prevStatus => [
        ...prevStatus.filter(item => item !== 'all clear'),
        'reviewRetirementAccountThatWasAddedByInstitution',
      ])
    }
  }, [person.age, isRequiredToReviewRetirementAccount, institutionalAccount])

  useEffect(() => {
    if (
      mode === 'spending' &&
      _.get(spendingModifiedCases, 'spendingScenario.budget.dollarAmountNeeded', 0) <= 0
    ) {
      setActionItemStatus(prevStatus => [
        ...prevStatus.filter(item => item !== 'all clear'),
        'needAmountEqualToZero',
      ])
    }
  }, [mode, spendingBaseCases, spendingModifiedCases])

  useEffect(() => {
    const birthYear = dayjs(person.birthDate).year()
    const currentYear = dayjs().year()
    const ageThisYear = currentYear - birthYear // age the user will turn this year
    if (
      enableCatchupContributions &&
      mode !== 'spending' &&
      ageThisYear >= 50 &&
      employerAccounts.findIndex(x => catchUpTypes.includes(x.planType)) !== -1
    ) {
      if (ageThisYear < 60) {
        setActionItemStatus(prevStatus => [
          ...prevStatus.filter(item => item !== 'all clear'),
          'catchUp50to59',
        ])
      } else if (ageThisYear < 64) {
        setActionItemStatus(prevStatus => [
          ...prevStatus.filter(item => item !== 'all clear'),
          'catchUp60to63',
        ])
      } else {
        setActionItemStatus(prevStatus => [
          ...prevStatus.filter(item => item !== 'all clear'),
          'catchUpOver64',
        ])
      }
    }
  }, [
    employerAccounts,
    enableCatchupContributions,
    institutionalAccount?.planType,
    mode,
    person.birthDate,
  ])

  const handleEnableSpending = async () => {
    try {
      if (person.retirementAge > person.age) {
        const personPatch = { retirementAge: person.age }
        updatePerson(personPatch)
      }
      setState('idle')
      await enableSpendingMode()
      setSpending(true)
      setIsModalOpen(false)
    } catch (err) {
      console.error(err)
      setState('error')
    }
  }
  const handleDisableSpending = async () => {
    if (spending === false) return
    try {
      setState('idle')
      await disableSpendingMode()
      setSpending(false)
      setIsModalOpen(false)
    } catch (err) {
      console.error(err)
      setState('error')
    }
  }

  const handleSpendownModeling = async () => {
    enableSpendDownModeling()
    const result = await getSurvivorPensionOptions()
    if (selectedInstitutionalPension.pensionId === null) {
      if (!_.isEmpty(result)) {
        return history.push('/welcome/pension-options', { from: '/home' })
      }
    }
    return history.push('/spending')
  }

  const handleAccountRedirect = async () => {
    return history.push('/accounts/add')
  }

  const handleRetirementAccountReview = async () => {
    const typeToPathKey = {
      Annuity: 'annuity',
      Institutional: 'institutional',
      NonGc: 'non-gc',
      Pension: 'pension',
    }
    return history.push(
      `/accounts/add/${typeToPathKey[institutionalAccount.type]}/${
        planTypeIdToAccountKey[institutional.planType]
      }/intro`,
      {
        id: institutionalAccount?.id,
      }
    )
  }

  const handleBudgetRedirect = async () => {
    return history.push('/spending/budget')
  }

  if (mode === 'retMoreThanThreeYearsAway') {
    return (
      <Section>
        <Section.NameInvestmentWrapper>
          <Section.Name>Hi {person.displayName}!</Section.Name>
          <Section.InvestmentBalance>
            <Section.InvestmentTitle>Investment accounts balance</Section.InvestmentTitle>
            <Section.InvestmentAmount>
              ${numberToDollars(accountsBalance, true)}
            </Section.InvestmentAmount>
          </Section.InvestmentBalance>
        </Section.NameInvestmentWrapper>
        <Spacer space='10px' />

        <Section.EncouragementText>
          <SVGWrapper
            svg={PiggyBank}
            fill={theme.piggyBankColor}
            hoverFill={theme.piggyBankHoverColor}
            size='custom46'
            cursor='default'
          />
          Your future self will be grateful for sacrifices you're making to save!
        </Section.EncouragementText>
        <Spacer space='10px' />

        <Section.ActionItemsBox>
          <Section.ActionItemsTitle>Action items</Section.ActionItemsTitle>
          {actionItemsStatus.includes('add at least one account') && (
            <Section.ActionItemsMessage warning>
              <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
              <Section.ActionItemMessageText>
                CRITICAL: Add at least one account intended for living expenses in retirement before
                beginning to plan.{' '}
                <Section.Link onClick={handleAccountRedirect}>Add account now</Section.Link>
              </Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
          {actionItemsStatus.includes('needs update') && (
            <Section.ActionItemsMessage warning>
              <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
              <Section.ActionItemMessageText>
                Update investment account data; it's been over a year
              </Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
          {actionItemsStatus.includes('needs suggestion') && (
            <Section.ActionItemsMessage>
              <Section.Triangle />{' '}
              <Section.ActionItemMessageText>
                Get your withdrawal strategy for 2020
              </Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
          {actionItemsStatus.includes('all clear') && (
            <Section.ActionItemsMessage>
              <SVGWrapper svg={SmileyFace} fill='#3A4D57' size='large' />
              <Section.ActionItemMessageText>All clear!</Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
          {actionItemsStatus.includes('ageEqualOrGreaterThanRetirement') && (
            <Section.ActionItemsMessage>
              <Section.Triangle />{' '}
              <Section.ActionItemMessageText>
                Looks like you've reached your stated retirement age-Congratulations! Check your
                next steps:&nbsp;
                <HelpIcon tooltip={TooltipText.areYouRetired()} />
              </Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
          {actionItemsStatus.includes('lumpSumPensionPayoutExpected') && (
            <Section.ActionItemsMessage warning>
              <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
              <Section.ActionItemMessageText>
                Have you received the lump sum pension payout yet? Our projections assume you invest
                it for future retirement expenses. Update your payout status.&nbsp;
                <HelpIcon tooltip={TooltipText.lumpSumPensionPayout()} />
              </Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
          {actionItemsStatus.includes('lumpSumPensionMayBeEligible') && (
            <LumpSumPensionMayBeEligible />
          )}
          {actionItemsStatus.includes('reviewRetirementAccountThatWasAddedByInstitution') && (
            <Section.ActionItemsMessage warning>
              <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
              <Section.ActionItemMessageText>
                {template.sponsorName} added your current retirement plan information. Please review
                and complete any missing data.&nbsp;
                <Section.Link onClick={handleRetirementAccountReview}>Review account</Section.Link>
              </Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
          {actionItemsStatus.includes('catchUp50to59') && (
            <Section.ActionItemsMessage warning>
              <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
              <Section.ActionItemMessageText>
                You have the chance to save a little more: The IRS permits those aged 50+ in the
                current calendar year to make catch-up contributions to their company’s retirement
                plan. The standard contribution limit in {dayjs().year()} is $
                {standardLimit?.toLocaleString()} and you may contribute up to an additional $
                {standardCatchup?.maximumAmount?.toLocaleString()} in catch-up contributions for a
                retirement plan account maximum contribution of $
                {(standardLimit + standardCatchup?.maximumAmount).toLocaleString()}.
              </Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
          {actionItemsStatus.includes('catchUp60to63') && (
            <Section.ActionItemsMessage warning>
              <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
              <Section.ActionItemMessageText>
                You have the chance to save a little more: The IRS permits those aged 60-63 in the
                current calendar year to make a higher catch-up contribution to their company’s
                retirement plan. The standard contribution limit in {dayjs().year()} is $
                {standardLimit?.toLocaleString()} and the standard catch-up contribution limit is $
                {standardCatchup?.maximumAmount?.toLocaleString()}. With the higher catch-up
                contribution limit, you may contribute up to an additional $
                {(
                  additionalCatchup?.maximumAmount - standardCatchup?.maximumAmount
                ).toLocaleString()}{' '}
                for a total catch-up contribution of $
                {additionalCatchup?.maximumAmount?.toLocaleString()}, making the retirement plan
                account maximum contribution $
                {(standardLimit + additionalCatchup?.maximumAmount)?.toLocaleString()}.
              </Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
          {actionItemsStatus.includes('catchUpOver64') && (
            <Section.ActionItemsMessage warning>
              <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
              <Section.ActionItemMessageText>
                Be aware: You may need to reduce your retirement plan contributions. The IRS permits
                those aged 64+ in the current calendar year to make catch-up contributions of up to
                the regular catch-up contribution limit of $
                {standardCatchup?.maximumAmount?.toLocaleString()} for {dayjs().year()}. With the
                standard contribution limit of ${standardLimit?.toLocaleString()}, this means the
                maximum contribution you can make to your company’s retirement plan is $
                {(standardLimit + standardCatchup?.maximumAmount).toLocaleString()}.
              </Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
        </Section.ActionItemsBox>

        <Spacer space='30px' />
      </Section>
    )
  }

  if (mode === 'retWithinThreeYears') {
    return (
      <Section>
        <Section.NameInvestmentWrapper>
          <Section.Name>Hi {person.displayName}!</Section.Name>
          <Section.InvestmentBalance>
            <Section.InvestmentTitle>Investment accounts balance</Section.InvestmentTitle>
            <Section.InvestmentAmount>
              ${numberToDollars(accountsBalance, true)}
            </Section.InvestmentAmount>
          </Section.InvestmentBalance>
        </Section.NameInvestmentWrapper>
        <Spacer space='10px' />

        <Section.EncouragementText>
          {spending ? (
            <SVGWrapper
              svg={PiggyBank}
              fill={theme.spendingPiggyColor}
              hoverFill={theme.spendingHoverPiggyColor}
              size='custom46'
              cursor='default'
              css={css`
                transform: scale(-1, -1);
              `}
            />
          ) : (
            <SVGWrapper
              svg={PiggyBank}
              fill={theme.piggyBankColor}
              hoverFill={theme.piggyBankHoverColor}
              size='custom46'
              cursor='default'
            />
          )}
          You're busy saving for your future but your retirement is just around the corner!!
        </Section.EncouragementText>
        <Spacer space='16px' />

        {!spending && (
          <Section.IndentedSection>
            <Section.ButtonAndHelpWrapper>
              <Section.ButtonContainer>
                <Section.CustomButton active={!spending} onClick={handleDisableSpending}>
                  <SVGWrapper
                    svg={PiggyBank}
                    fill={theme.piggyBankColor}
                    hoverFill={theme.piggyBankColor}
                    size='large'
                    cursor='default'
                  />
                </Section.CustomButton>
                <Section.CustomButton
                  active={spending}
                  flipped
                  onClick={() => setIsModalOpen(true)}>
                  <SVGWrapper
                    svg={PiggyBank}
                    fill={theme.spendingPiggyColor}
                    hoverFill={theme.spendingHoverPiggyColor}
                    size='large'
                    cursor='default'
                  />
                </Section.CustomButton>
              </Section.ButtonContainer>
              <HelpIcon tooltip={TooltipText.homeToggle()} />
            </Section.ButtonAndHelpWrapper>
          </Section.IndentedSection>
        )}

        <SharedModal
          isModalOpen={isModalOpen}
          title='We just want to double check with you...'
          toggleModal={() => setIsModalOpen(modal => !modal)}
          isHeightRestricted={false}>
          <Section.ModalText>
            <Spacer space='18px' />
            <p>
              Changing the piggy bank toggle to the spending side means you are{' '}
              <Section.OrangeText>
                retired, no longer accumulating savings for retirement and ready to withdraw money
                for retirement income
              </Section.OrangeText>
            </p>
            <Spacer space='4px' />
            <p>
              <Section.BoldText>This action cannot be undone. </Section.BoldText>
              Be certain you are retired before selecting.
            </p>
            <Spacer space='6px' />
          </Section.ModalText>
          {state === 'error' && <FormError />}
          {state === 'loading' ? (
            <Loading />
          ) : (
            <Section.ButtonsBox style={{ marginBottom: '0px' }}>
              <Button primary label='I am retired' onClick={handleEnableSpending} width='114px' />
            </Section.ButtonsBox>
          )}
        </SharedModal>

        <Spacer space='10px' />

        <Section.IndentedSection>
          <Section.ButtonAndHelpWrapper>
            <Section.CanYouRetire>Can you retire yet? Find out:</Section.CanYouRetire>

            <Section.SpendDownModelingBtn onClick={handleSpendownModeling}>
              Spend-down modeling
            </Section.SpendDownModelingBtn>
            <HelpIcon tooltip={TooltipText.whatIsSpendDownModeling()} />
          </Section.ButtonAndHelpWrapper>
        </Section.IndentedSection>
        <Spacer space='30px' />

        <Section.ActionItemsBox>
          <Section.ActionItemsTitle>Action items</Section.ActionItemsTitle>
          {actionItemsStatus.includes('add at least one account') && (
            <Section.ActionItemsMessage warning>
              <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
              <Section.ActionItemMessageText>
                CRITICAL: Add at least one account intended for living expenses in retirement before
                beginning to plan.{' '}
                <Section.Link onClick={handleAccountRedirect}>Add account now</Section.Link>
              </Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
          {actionItemsStatus.includes('needs update') && (
            <Section.ActionItemsMessage warning>
              <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />
              <Section.ActionItemMessageText>
                Update investment account data; it's been over a year
              </Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
          {actionItemsStatus.includes('needs suggestion') && (
            <Section.ActionItemsMessage>
              <Section.Triangle />{' '}
              <Section.ActionItemMessageText>
                Get your withdrawal strategy for 2020
              </Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
          {actionItemsStatus.includes('all clear') && (
            <Section.ActionItemsMessage>
              <SVGWrapper svg={SmileyFace} fill='#3A4D57' size='large' />{' '}
              <Section.ActionItemMessageText>All clear!</Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
          {actionItemsStatus.includes('ageEqualOrGreaterThanRetirement') && (
            <Section.ActionItemsMessage>
              <Section.Triangle />{' '}
              <Section.ActionItemMessageText>
                Looks like you've reached your stated retirement age-Congratulations! Check your
                next steps:&nbsp;
                <HelpIcon tooltip={TooltipText.areYouRetired()} />
              </Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
          {actionItemsStatus.includes('lumpSumPensionPayoutExpected') && (
            <Section.ActionItemsMessage warning>
              <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
              <Section.ActionItemMessageText>
                Have you received the lump sum pension payout yet? Our projections assume you invest
                it for future retirement expenses. Update your payout status.&nbsp;
                <HelpIcon tooltip={TooltipText.lumpSumPensionPayout()} />
              </Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
          {actionItemsStatus.includes('lumpSumPensionMayBeEligible') && (
            <LumpSumPensionMayBeEligible />
          )}
          {actionItemsStatus.includes('reviewRetirementAccountThatWasAddedByInstitution') && (
            <Section.ActionItemsMessage warning>
              <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
              <Section.ActionItemMessageText>
                {template.sponsorName} added your current retirement plan information. Please review
                and complete any missing data.&nbsp;
                <Section.Link onClick={handleRetirementAccountReview}>Review account</Section.Link>
              </Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
          {actionItemsStatus.includes('catchUp50to59') && (
            <Section.ActionItemsMessage warning>
              <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
              <Section.ActionItemMessageText>
                You have the chance to save a little more: The IRS permits those aged 50+ in the
                current calendar year to make catch-up contributions to their company’s retirement
                plan. The standard contribution limit in {dayjs().year()} is $
                {standardLimit?.toLocaleString()} and you may contribute up to an additional $
                {standardCatchup?.maximumAmount?.toLocaleString()} in catch-up contributions for a
                retirement plan account maximum contribution of $
                {(standardLimit + standardCatchup?.maximumAmount).toLocaleString()}.
              </Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
          {actionItemsStatus.includes('catchUp60to63') && (
            <Section.ActionItemsMessage warning>
              <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
              <Section.ActionItemMessageText>
                You have the chance to save a little more: The IRS permits those aged 60-63 in the
                current calendar year to make a higher catch-up contribution to their company’s
                retirement plan. The standard contribution limit in {dayjs().year()} is $
                {standardLimit?.toLocaleString()} and the standard catch-up contribution limit is $
                {standardCatchup?.maximumAmount?.toLocaleString()}. With the higher catch-up
                contribution limit, you may contribute up to an additional $
                {(
                  additionalCatchup?.maximumAmount - standardCatchup?.maximumAmount
                ).toLocaleString()}{' '}
                for a total catch-up contribution of $
                {additionalCatchup?.maximumAmount?.toLocaleString()}, making the retirement plan
                account maximum contribution $
                {(standardLimit + additionalCatchup?.maximumAmount)?.toLocaleString()}.
              </Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
          {actionItemsStatus.includes('catchUpOver64') && (
            <Section.ActionItemsMessage warning>
              <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
              <Section.ActionItemMessageText>
                Be aware: You may need to reduce your retirement plan contributions. The IRS permits
                those aged 64+ in the current calendar year to make catch-up contributions of up to
                the regular catch-up contribution limit of $
                {standardCatchup?.maximumAmount?.toLocaleString()} for {dayjs().year()}. With the
                standard contribution limit of ${standardLimit?.toLocaleString()}, this means the
                maximum contribution you can make to your company’s retirement plan is $
                {(standardLimit + standardCatchup?.maximumAmount).toLocaleString()}.
              </Section.ActionItemMessageText>
            </Section.ActionItemsMessage>
          )}
        </Section.ActionItemsBox>
        <Spacer space='30px' />
      </Section>
    )
  }

  return (
    <Section>
      <Section.NameInvestmentWrapper>
        <Section.Name>Hi {person.displayName}!</Section.Name>
        <Section.InvestmentBalance>
          <Section.InvestmentTitle>Investment accounts balance</Section.InvestmentTitle>
          <Section.InvestmentAmount>
            ${numberToDollars(accountsBalance, true)}
          </Section.InvestmentAmount>
        </Section.InvestmentBalance>
      </Section.NameInvestmentWrapper>
      <Spacer space='10px' />

      <Section.EncouragementText>
        {spending ? (
          <SVGWrapper
            svg={PiggyBank}
            fill={theme.spendingPiggyColor}
            hoverFill={theme.spendingHoverPiggyColor}
            size='custom46'
            cursor='default'
            css={css`
              transform: scale(-1, -1);
            `}
          />
        ) : (
          <SVGWrapper
            svg={PiggyBank}
            fill={theme.piggyBankColor}
            hoverFill={theme.piggyBankHoverColor}
            size='custom46'
            cursor='default'
          />
        )}
        We hope sincerely that retirement is going well! Let us help you make your money last.
      </Section.EncouragementText>
      <Spacer space='10px' />

      <Section.ActionItemsBox>
        <Section.ActionItemsTitle>Action items</Section.ActionItemsTitle>
        {actionItemsStatus.includes('add at least one account') && (
          <Section.ActionItemsMessage warning>
            <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
            <Section.ActionItemMessageText>
              CRITICAL: Add at least one account intended for living expenses in retirement before
              beginning to plan.{' '}
              <Section.Link onClick={handleAccountRedirect}>Add account now</Section.Link>
            </Section.ActionItemMessageText>
          </Section.ActionItemsMessage>
        )}
        {actionItemsStatus.includes('needs update') && (
          <Section.ActionItemsMessage warning>
            <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
            <Section.ActionItemMessageText>
              Update investment account data; it's been over a year
            </Section.ActionItemMessageText>
          </Section.ActionItemsMessage>
        )}
        {actionItemsStatus.includes('needs suggestion') && (
          <Section.ActionItemsMessage>
            <Section.Triangle />
            <Section.ActionItemMessageText>
              Get your withdrawal strategy for 2020
            </Section.ActionItemMessageText>
          </Section.ActionItemsMessage>
        )}
        {actionItemsStatus.includes('all clear') && (
          <Section.ActionItemsMessage>
            <SVGWrapper svg={SmileyFace} fill='#3A4D57' size='large' />
            <Section.ActionItemMessageText>All clear!</Section.ActionItemMessageText>
          </Section.ActionItemsMessage>
        )}
        {actionItemsStatus.includes('accountContributionsUpdateRequired') && (
          <Section.ActionItemsMessage>
            <Section.Triangle />
            <Section.ActionItemMessageText>
              Update account contributions information to reflect whether or not you are still
              contributing to a particular account since retiring. &nbsp;
              <HelpIcon tooltip={TooltipText.updateAccountContributionsAfterRetirement()} />
            </Section.ActionItemMessageText>
          </Section.ActionItemsMessage>
        )}
        {actionItemsStatus.includes('lumpSumPensionPayoutExpected') && (
          <Section.ActionItemsMessage warning>
            <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
            <Section.ActionItemMessageText>
              Have you received the lump sum pension payout yet? Our projections assume you invest
              it for future retirement expenses. Update your payout status.&nbsp;
              <HelpIcon tooltip={TooltipText.lumpSumPensionPayout()} />
            </Section.ActionItemMessageText>
          </Section.ActionItemsMessage>
        )}
        {actionItemsStatus.includes('lumpSumPensionMayBeEligible') && (
          <LumpSumPensionMayBeEligible />
        )}
        {actionItemsStatus.includes('spendingShortfall') && (
          <Section.ActionItemsMessage warning>
            <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
            <Section.ActionItemMessageText>
              You have a shortfall—meaning you cannot meet your minimum need amount at some point
              during your retirement. Please revisit your{' '}
              <Section.Link onClick={handleBudgetRedirect}>income need and want</Section.Link>{' '}
              and/or make certain that all of your income sources have been added and their
              information is up to date.
            </Section.ActionItemMessageText>
          </Section.ActionItemsMessage>
        )}
        {actionItemsStatus.includes('reviewRetirementAccountThatWasAddedByInstitution') && (
          <Section.ActionItemsMessage warning>
            <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
            <Section.ActionItemMessageText>
              {template.sponsorName} added your current retirement plan information. Please review
              and complete any missing data.&nbsp;
              <Section.Link onClick={handleRetirementAccountReview}>Review account</Section.Link>
            </Section.ActionItemMessageText>
          </Section.ActionItemsMessage>
        )}
        {actionItemsStatus.includes('needAmountEqualToZero') && (
          <Section.ActionItemsMessage warning>
            <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
            <Section.ActionItemMessageText>
              Give more meaning to your advice and{' '}
              <Section.Link onClick={handleBudgetRedirect}>add a budget</Section.Link> today. It can
              be as simple as estimating your minimum need for those mandatory expenses like
              housing, groceries, and medical.
            </Section.ActionItemMessageText>
          </Section.ActionItemsMessage>
        )}
      </Section.ActionItemsBox>
      <Spacer space='30px' />
    </Section>
  )
}

export default inject('store')(observer(Intro))
