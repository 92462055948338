import { Section } from '../styles'
import { Warning } from '../../../assets/icons'
import { SVGWrapper, HelpIcon, TooltipText } from '../../../components'

const LumpSumPensionMayBeEligible = () => {
  return (
    <Section.ActionItemsMessage warning>
      <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
      <Section.ActionItemMessageText>
        Your plan allows a lump sum pension payout and based on your age, you may be eligible to
        receive a payment. If you’ve already received a lump sum payment, please update the payout
        status. If you have invested the funds elsewhere, please update your investments.
        <HelpIcon tooltip={TooltipText.lumpSumPensionPayout()} />
      </Section.ActionItemMessageText>
    </Section.ActionItemsMessage>
  )
}

export default LumpSumPensionMayBeEligible
