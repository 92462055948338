import { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { DoughnutChart } from '../../shared'
import { Layout } from '../elements'
import { MinusIcon, PlusIcon } from '../../../components'

const ChartContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media only screen and (max-width: 450px) {
    flex-direction: column;

    div {
      display: flex;
      flex-direction: column;
    }
  }
`
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 45px;
  margin-left: 15px;
  & svg {
    fill: ${p => p.theme.whatIsAGoalColor};
    transition: color 0.3s, fill 0.3s;
  }
  &:hover {
    & svg {
      fill: ${p => p.theme.whatIsAGoalHoverColor};
    }
  }
  &:active {
    & svg {
      fill: ${p => p.theme.whatIsAGoalActiveColor};
    }
  }
`
const IconContainer = styled.span`
  display: inline-block;
  width: 40px;
  height: 24px;
  padding: 0 8px;
`

const StyledLabel = styled(Layout.Header)`
  margin: auto;
  text-align: center;
  color: ${props => (props.isOld ? '#7a8396' : '#038ab7')};
`

const StyledDoughnut = styled(DoughnutChart)`
  margin: 24px auto;
`

const Header = styled(Layout.DisplaySmall)`
  margin: 24px;
  text-align: center;
`

const LabeledDoughnut = ({ label, ...props }) => (
  <div>
    <StyledLabel {...props}>{label}</StyledLabel>
    <StyledDoughnut {...props} />
  </div>
)

class SellingStockChart extends Component {
  state = { active: false }

  toggleActive = () => this.setState({ active: !this.state.active })

  render() {
    const { currentPercentage, implementPercentage } = this.props

    return (
      <div>
        <ToggleContainer onClick={this.toggleActive}>
          <IconContainer>{this.state.active ? <MinusIcon /> : <PlusIcon />}</IconContainer>
          <span
            css={css`
              font-size: 24px;
              color: #4a4a4a;
            `}>
            Percentage of company stock in current employer plan account
          </span>
        </ToggleContainer>

        {this.state.active && (
          <>
            <Header>By making this change, your risk will be reduced</Header>

            <ChartContainer>
              <LabeledDoughnut
                height={175}
                label='Current percentage'
                isOld
                percent={currentPercentage}
              />
              <LabeledDoughnut
                height={175}
                label='If implemented'
                isOld={false}
                percent={implementPercentage}
              />
            </ChartContainer>
          </>
        )}
      </div>
    )
  }
}

export default SellingStockChart
