const PlusIcon = props => (
  <svg data-name='Layer 1' viewBox='0 0 24 24' {...props}>
    <title>add-normal-c</title>
    <path
      data-name='icn/add-normal'
      d='M21.6 12a9.6 9.6 0 0 0-19.2 0M0 12a12 12 0 0 1 24 0M2.4 12a9.6 9.6 0 0 0 19.2 0m2.4 0a12 12 0 0 1-24 0m11-1H6v2h5v5h2v-5h5v-2h-5V6h-2v5z'
    />
  </svg>
)

export default PlusIcon
