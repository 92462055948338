/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'
import { FORM_ERROR } from 'final-form'
import { Form, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import styled from '@emotion/styled'

import { LargeCheckbox } from '../../../guided-toolbox/checkbox'
import { Layout } from '../elements'
import { MinusIcon, PlusIcon, Spacer } from '../../../components'
import { DoughnutChart } from '../../shared'
import { BackButton, NextButton } from '../../../components/styled'
import { Page } from './style'

function CompanyStock(props) {
  const {
    history,
    spendDownOffboarding: { incrementStep },
    store: {
      institutional,
      plan: { sponsorName, recordKeeperName },
    },
  } = props
  const [active, setActive] = useState(false)

  const spendingAcceptedCase = _.get(props, 'store.spendingAcceptedCase', {})

  const companyStocksSellAll = _.get(spendingAcceptedCase, 'companyStocks', []).filter(
    stock => stock.percentToSell === 100
  )

  const companyStocksBeingSold = _.orderBy(
    _.get(spendingAcceptedCase, 'companyStocks', []).filter(stock => stock.percentToSell > 0),
    [stock => stock.percentToSell, stock => stock.securityName.toLowerCase()],
    ['desc', 'asc']
  )

  // Total Value of all Company Stocks / Institutional Balance
  const currentPercentage = Math.round(
    (_.get(spendingAcceptedCase, 'companyStocks', []).reduce(
      (total, stock) => total + stock.totalValue,
      0
    ) /
      (institutional.balance || 1)) *
      100
  )

  // (Total Value of all Company Stocks - Total Amount to Sell) / Institutional Balance
  const implementPercentage =
    ((_.get(spendingAcceptedCase, 'companyStocks', []).reduce(
      (total, stock) => total + stock.totalValue,
      0
    ) -
      _.get(spendingAcceptedCase, 'companyStocks', []).reduce(
        (total, stock) => total + stock.amountToSell,
        0
      )) /
      (institutional.balance || 1)) *
    100

  async function handleSubmit(values) {
    try {
      await Promise.resolve()

      const {
        history,
        spendDownOffboarding: { incrementStep },
      } = props

      incrementStep(history, 'company-stock')
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const { config } = props.store

  function initialValues() {
    // map across companyStocksSellAll to determine how many fields to display
    return {
      companyStocksSellAll: companyStocksSellAll.map(({ positionId, securityName }) => ({
        positionId,
        securityName,
        confirmed: false,
      })),
    }
  }

  function validate(value) {
    // make sure they've confirmed sell-off of company stock(s) being sold to 100%
    return value ? undefined : 'Please confirm your company stock decision before continuing.'
  }

  const onSubmit = values => {
    return handleSubmit(values)
  }

  return (
    <Page.Container
      css={css`
        color: #4a606a;
        max-width: 1000px;
        margin: 0 auto;
        line-height: 1.5rem;
      `}>
      <Spacer space='10px' />
      <Page.CompanyStockTitle>Before we can finalize your plan</Page.CompanyStockTitle>
      <Page.CompanyStockWrapper>
        <Page.CompanyStockStockTitle>Company stock will be sold</Page.CompanyStockStockTitle>

        <Page.CompanyStockStockParagraph>
          Your plan includes a decision to sell some of the company stock you hold in your{' '}
          {sponsorName} retirement plan account and to have the proceeds from that sale be allocated
          proportionately amongst your other account investments. <br />
          <br />
          If you decide to have us implement changes to your {sponsorName} retirement plan account,
          we will send {recordKeeperName} instructions to:
        </Page.CompanyStockStockParagraph>
        <Spacer space='10px' />

        <Page.CompanyStockStockPercentageToSell>
          <div
            css={css`
              margin: 24px 0;
            `}>
            {companyStocksBeingSold.map(companyStock => (
              <div key={companyStock.positionId}>
                Sell {companyStock.percentToSell}% of {companyStock.securityName}
              </div>
            ))}
          </div>
        </Page.CompanyStockStockPercentageToSell>
        <Spacer space='10px' />

        <Page.ToggleContainer onClick={() => setActive(!active)}>
          <Page.IconContainer>{active ? <MinusIcon /> : <PlusIcon />}</Page.IconContainer>
          <span
            css={css`
              font-size: 24px;
              color: #4a4a4a;
            `}>
            Percentage of company stock in current employer plan account
          </span>
        </Page.ToggleContainer>

        <Spacer space='10px' />

        {active && (
          <>
            <Page.RiskWillBeReduced>
              By making this change, your risk will be reduced
            </Page.RiskWillBeReduced>
            <Spacer space='10px' />

            <Page.SVGWrapper>
              <Page.SVGColumn>
                <Page.SVGCurrentTitle>Current percentage</Page.SVGCurrentTitle>
                <Page.SVGContainer>
                  <DoughnutChart percent={currentPercentage} height={130} />
                </Page.SVGContainer>
              </Page.SVGColumn>

              <Page.SVGColumn>
                <Page.SVGImplementedTitle>If implemented</Page.SVGImplementedTitle>
                <Page.SVGContainer>
                  <DoughnutChart percent={implementPercentage} height={130} isOld={false} />
                </Page.SVGContainer>
              </Page.SVGColumn>
            </Page.SVGWrapper>
          </>
        )}

        {companyStocksSellAll?.length > 0 && (
          <Form
            initialValues={initialValues()}
            onSubmit={onSubmit}
            mutators={{ ...arrayMutators }}
            subscription={{ touched: true, errors: true, submitting: true, submitError: true }}
            render={({ touched, errors, submitting, submitError, handleSubmit }) => {
              const submittingError = !submitting && submitError
              const termsError =
                !submitting &&
                touched.companyStocksSellAll &&
                errors.companyStocksSellAll &&
                'Please confirm your company stock decision before continuing.'

              return (
                <form onSubmit={handleSubmit}>
                  <FieldArray name='companyStocksSellAll'>
                    {({ fields }) =>
                      fields.value.length > 0 && (
                        <StyledSelectionBox>
                          <StyledSelectionTitle>
                            Confirm company stock will be sold
                          </StyledSelectionTitle>

                          {fields.map((name, index) => (
                            <Field
                              key={fields.value[index].positionId}
                              name={`${name}.confirmed`}
                              validate={validate}
                              render={({ input }) => (
                                <StyledSelectionField>
                                  <LargeCheckbox
                                    checked={input.value === true}
                                    onChange={
                                      submitting
                                        ? () => {}
                                        : () => {
                                            input.onFocus()
                                            input.onChange(!input.value)
                                            input.onBlur()
                                          }
                                    }
                                  />
                                  <StyledCheckboxLabel
                                    onClick={
                                      submitting
                                        ? () => {}
                                        : () => {
                                            input.onFocus()
                                            input.onChange(!input.value)
                                            input.onBlur()
                                          }
                                    }>
                                    Please confirm you are aware that <strong>100%</strong> of your{' '}
                                    {fields.value[index].securityName} <strong>will be sold</strong>{' '}
                                    if you elect to have GuidedChoice implement the changes to your{' '}
                                    {config.template.sponsorName} retirement plan account.
                                  </StyledCheckboxLabel>
                                </StyledSelectionField>
                              )}
                            />
                          ))}
                        </StyledSelectionBox>
                      )
                    }
                  </FieldArray>

                  <Layout.ErrorMessage>{submittingError || termsError}</Layout.ErrorMessage>
                  <div
                    css={css`
                      display: flex;
                      justify-content: space-between;
                      margin: 30px 0;
                    `}>
                    <BackButton backgroundColor='#FFFFFF' onClick={() => history.goBack()}>
                      Back
                    </BackButton>
                    <NextButton onClick={() => onSubmit}>Next</NextButton>
                  </div>
                </form>
              )
            }}
          />
        )}

        {companyStocksSellAll?.length === 0 && (
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              margin: 30px 0;
            `}>
            <BackButton backgroundColor='#FFFFFF' onClick={() => history.goBack()}>
              Back
            </BackButton>
            <NextButton onClick={() => incrementStep(history, 'company-stock')}>Next</NextButton>
          </div>
        )}
      </Page.CompanyStockWrapper>
    </Page.Container>
  )
}

export default inject('store', 'spendDownOffboarding')(observer(CompanyStock))

const StyledSelectionTitle = styled(Layout.SelectionTitle)`
  color: #ce0b24;
`

const StyledSelectionBox = styled(Layout.SelectionBox)`
  margin: 28px 24px;
  border-color: #ce0b24;
`
const StyledSelectionField = styled(Layout.SelectionField)`
  display: flex;

  label {
    display: inline-flex;
    width: 36px;
    margin: 0;

    /* enlarge checkbox */
    transform: scale(1.5, 1.5) translate(5px, 5px);
  }
`
const StyledCheckboxLabel = styled(Layout.DisplaySmall)`
  display: inline;
  width: 96%;
  margin-left: 1%;
  color: #4a606a;
  cursor: pointer;
  align-items: center;
  strong {
    color: #ce0b24;
    display: inline;
  }
`
