/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { Component } from 'react'
import styled from '@emotion/styled'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import _ from 'lodash'

import {
  TooltipText,
  HelpIcon,
  PercentInput,
  Slider,
  MinusIcon,
  PlusIcon,
} from '../../../components'
import { numberToDollars } from '../../../utils'
import { DoughnutChart } from '../../shared'
import { BorderedCard, CardTitle } from './EditDrawerStyle'

const RestrictionLabel = styled.div`
  color: #7a8e96;
  font-size: 0.875rem;
  margin: 12px 0 8px;
`
const CompanyInsiderWarning = styled.div`
  color: #f15d7f;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.35;
  margin: 8px 0 12px;
`
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 32px;
  & svg {
    fill: #7a8e96;
    transition: color 0.3s, fill 0.3s;
  }
  &:hover {
    & svg {
      fill: #053240;
    }
  }
  &:active {
    & svg {
      fill: #0495bf;
    }
  }
`
const IconContainer = styled.span`
  display: inline-block;
  width: 40px;
  height: 24px;
  padding: 0 8px;
`

const CompanyStockFields = ({ fields }) => (
  <div>
    {fields.map((name, index) => (
      <Field
        key={name}
        name={`${name}.restricted`}
        render={restricted =>
          restricted.input.value ? null : (
            <Field
              name={`${name}.totalValue`}
              render={totalValue => (
                <div>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}>
                    <div
                      css={css`
                        flex: 0 1 300px;
                      `}>
                      <span
                        css={css`
                          color: #022a3a;
                          font-size: 18px;
                        `}>
                        Sell{' '}
                      </span>
                      <Field
                        name={`${name}.amountToSell`}
                        render={amountToSell => (
                          <Field
                            name={`${name}.percentToSell`}
                            format={v => v}
                            parse={v => v}
                            render={({ input, meta }) => (
                              <PercentInput
                                name={input.name}
                                value={input.value === '' ? null : input.value}
                                onChange={(name, value) => {
                                  input.onChange(value)
                                  amountToSell.input.onChange(
                                    Math.round((value / 100) * totalValue.input.value)
                                  )
                                }}
                                onBlur={input.onBlur}
                                error={meta.error}
                                showError={false}
                                placeholder='0'
                                width='80px'
                                css={css`
                                  display: inline-block;
                                  margin-right: 12px;
                                `}
                              />
                            )}
                          />
                        )}
                      />
                      <Field
                        name={`${name}.amountToSell`}
                        render={({ input }) => (
                          <span
                            css={css`
                              color: #4a606a;
                              font-size: 14px;
                            `}>
                            (${numberToDollars(input.value, true)})
                          </span>
                        )}
                      />
                      <Field
                        name={`${name}.securityName`}
                        render={({ input }) => (
                          <div
                            css={css`
                              color: #7a8e96;
                              font-size: 14px;
                              font-weight: 300;
                              line-height: 1.35;
                            `}>
                            {input.value}
                          </div>
                        )}
                      />
                    </div>
                    <Field
                      name={`${name}.amountToSell`}
                      render={amountToSell => (
                        <Field
                          name={`${name}.percentToSell`}
                          render={percentToSell => (
                            <div
                              css={css`
                                flex: 1 1 400px;
                              `}>
                              <Slider
                                value={percentToSell.input.value || 0}
                                onChange={value => {
                                  percentToSell.input.onChange(value)
                                  amountToSell.input.onChange(
                                    Math.round((value / 100) * totalValue.input.value)
                                  )
                                }}
                                step={1}
                                min={0}
                                max={100}
                              />
                            </div>
                          )}
                        />
                      )}
                    />
                  </div>
                  <div
                    css={css`
                      color: #f15d7f;
                      line-height: 1.35;
                      margin: 12px 0;
                    `}>
                    <Field
                      name={`${name}.percentToSell`}
                      validate={value => (value > 100 ? 'Cannot sell more than 100%' : undefined)}
                      render={percentToSell => percentToSell.meta.error || ''}
                    />
                  </div>
                </div>
              )}
            />
          )
        }
      />
    ))}
  </div>
)

class MakeChangesCompanyStock extends Component {
  state = { active: false }

  toggleActive = () => this.setState({ active: !this.state.active })

  render() {
    const {
      store: {
        features,
        institutionalAccount,
        primaryEmployerConfig,
        modifiedCase,
        recommendedCase,
      },
      editable: { companyStockIsEditable },
      values: { companyStocks },
    } = this.props

    if (!companyStockIsEditable) {
      return null
    }

    const { companyStockNames } = modifiedCase || recommendedCase

    const percentageOfAccount = Math.round(
      (companyStocks.reduce((total, stock) => total + stock.totalValue, 0) /
        (primaryEmployerConfig.balance || 1)) *
        100
    )

    return (
      <BorderedCard>
        <CardTitle>
          Company Stock in {_.get(institutionalAccount, 'name', 'your account')}
        </CardTitle>

        <RestrictionLabel>UNRESTRICTED</RestrictionLabel>

        {features.companyInsiderEnabled && (
          <CompanyInsiderWarning>
            Our initial recommendation always includes selling 100% of your unrestricted company
            stock. However, as a company insider, at this time you are not permitted to sell any
            company stock. You may simulate selling different amounts, but you'll have to return
            after the no trading period ends to make the sale.
          </CompanyInsiderWarning>
        )}

        <FieldArray name='companyStocks' component={CompanyStockFields} />

        <ToggleContainer onClick={this.toggleActive}>
          <IconContainer>{this.state.active ? <MinusIcon /> : <PlusIcon />}</IconContainer>
          <span
            css={css`
              font-size: 14px;
              color: #4a606a;
            `}>
            Risk and your company stock
          </span>
        </ToggleContainer>
        {this.state.active && (
          <div>
            <div
              css={css`
                font-size: 14px;
                color: #4a606a;
                line-height: 1.35;
                margin: 12px 0 12px 40px;
              `}>
              Percentage of current employer plan account in {companyStockNames}
            </div>
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
              `}>
              <div
                css={css`
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                `}>
                <div
                  css={css`
                    width: 100%;
                    color: #7a8e96;
                    font-size: 0.875rem;
                    line-height: 1.35;
                    text-align: center;
                    margin: 8px 0;
                  `}>
                  Percentage of account, currently
                </div>
                <DoughnutChart height={150} percent={percentageOfAccount} />
              </div>
              <div>
                <div
                  css={css`
                    margin: 16px 0;
                  `}>
                  <HelpIcon
                    helpLabel='Why we recommend selling 100%'
                    tooltip={TooltipText.companyStockHundredPercent()}
                  />
                </div>
                <div
                  css={css`
                    margin: 16px 0;
                  `}>
                  <HelpIcon
                    helpLabel="Determining what's right for you"
                    tooltip={TooltipText.companyStockDetermination()}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </BorderedCard>
    )
  }
}

export default MakeChangesCompanyStock
