import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'

import { Layout } from '../elements'
import { LargeCheckbox } from '../../../guided-toolbox/checkbox'
import { numberToDollars } from '../../../utils'

const StyledSelectionTitle = styled(Layout.SelectionTitle)`
  color: #ce0b24;
`
const StyledSelectionBox = styled(Layout.SelectionBox)`
  margin: 28px 24px;
  border-color: #ce0b24;
`
const StyledSelectionField = styled(Layout.SelectionField)`
  display: flex;

  label {
    display: inline-flex;
    width: 36px;
    margin: 0;

    /* enlarge checkbox */
    transform: scale(1.5, 1.5) translate(5px, 5px);

    color: #3e4155;
  }
`
const StyledCheckboxLabel = styled(Layout.DisplaySmall)`
  display: inline;
  width: 96%;
  margin-left: 1%;
  color: #4a606a;
  cursor: pointer;
  align-items: center;
  strong {
    color: #ce0b24;
    display: inline;
  }
`

const ContributionConfirmation = ({ isChecked, handleToggle, store }) => {
  const { baseCase, acceptedCase } = store

  const contributionDiff = acceptedCase.totalAnnualPercentage - baseCase.totalAnnualPercentage
  const contributionPositive = Math.sign(contributionDiff) >= 0
  const contributionChange = Math.abs(contributionDiff)
  const annualContributionChange = Math.abs(
    acceptedCase.totalAnnualSavings - baseCase.totalAnnualSavings
  )
  const weeklyContributionChange = annualContributionChange / 52

  return (
    <StyledSelectionBox>
      <StyledSelectionTitle>Confirm paycheck deduction will change</StyledSelectionTitle>
      <StyledSelectionField>
        <LargeCheckbox checked={isChecked} onChange={handleToggle} />
        <StyledCheckboxLabel onClick={handleToggle}>
          I understand my total{' '}
          <strong>
            contribution will {contributionPositive ? 'increase' : 'decrease'} by{' '}
            {numberToDollars(contributionChange, true)}%
          </strong>
          —which is approximately ${numberToDollars(annualContributionChange, true)} annually or $
          {numberToDollars(weeklyContributionChange, true)} weekly
        </StyledCheckboxLabel>
      </StyledSelectionField>
    </StyledSelectionBox>
  )
}

export default inject('store')(observer(ContributionConfirmation))
