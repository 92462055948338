import { css } from '@emotion/react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'
import { Form, useField, useFormState } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import * as yup from 'yup'
import _ from 'lodash'

import { API } from '../../../api'
import { Layout } from '../elements'
import { numberToDollars, reduceValidationError } from '../../../utils'
import ContributionComparison from './ContributionComparison'
import ContributionConfirmation from './ContributionConfirmation'
import ContributionButtons from './ContributionButtons'
import CatchupContributions from '../transactions/CatchupContributions'
import dayjs from 'dayjs'
import { useEffect } from 'react'

const Container = styled(Layout.Container)`
  margin: 24px auto;
  padding: 0 12px;
`
// hardcoded spacing and wrapping for checkbox
const DisplayXLarge = styled(Layout.DisplayXLarge)`
  margin: 56px 24px 24px;
  line-height: 1.45;
`
const DisplayLarge = styled(Layout.DisplayLarge)`
  margin: 8px 24px;
  color: #ce0b24;
`
const DisplaySmall = styled(Layout.DisplaySmall)`
  margin: 24px;
`

const schema = yup.object().shape({
  catchupContribStatus: yup
    .string()
    .nullable()
    .when('$enableCatchupContributions', (enableCatchupContributions, schema) =>
      enableCatchupContributions ? schema.oneOf(['Y', 'N'], 'Please select an option') : schema
    ),
  terms: yup
    .boolean()
    .when('$contributionChanged', (contributionChanged, schema) =>
      contributionChanged
        ? schema.equals([true], 'Please confirm contributions before continuing')
        : schema
    ),
})

// audit user accepting change in savings rate
function logAcceptSavingsRateChange() {
  const eventLog = {
    eventType: 'acceptSavingsRateChange',
    eventDescription: JSON.stringify({ status: 'accepted', domain: 'offboarding' }),
  }

  API.post('userLog', eventLog)
}

function TermsField() {
  const { input } = useField('terms')
  const { submitting } = useFormState({ subscription: { submitting: true } })

  function handleToggle() {
    if (submitting) {
      //
    } else {
      input.onFocus()
      input.onChange(!input.value)
      input.onBlur()
    }
  }

  return <ContributionConfirmation isChecked={input.value === true} handleToggle={handleToggle} />
}

function Contribution(props) {
  const { config, plan, standardLimit, standardCatchup, additionalCatchup, getLimits } = props.store
  const { enableCatchupContributions } = config
  const { limits } = plan
  const birthYear = dayjs(props.store.person.birthDate).year()
  const currentYear = dayjs().year()
  const ageThisYear = currentYear - birthYear

  const contributionChanged =
    _.get(props.store.baseCase, 'totalAnnualPercentage', 0) !==
    _.get(props.store.acceptedCase, 'totalAnnualPercentage', 0)

  useEffect(() => {
    getLimits()
  }, [getLimits])

  function buildSavingsRate(values) {
    const mapTaxType = { preTax: 1, afterTax: 2, roth: 5 }
    const preTaxValue = { P: values.preTaxRate, D: values.preTaxAmount }
    const rothValue = { P: values.rothRate, D: values.rothAmount }
    const afterTaxValue = { P: values.afterTaxRate, D: values.afterTaxAmount }
    const catchupPreTaxValue = { P: values.catchupPreTaxRate, D: values.catchupPreTaxAmount }
    const catchupRothValue = { P: values.catchupRothRate, D: values.catchupRothAmount }
    const catchupAfterTaxValue = { P: values.catchupAfterTaxRate, D: values.catchupAfterTaxAmount }

    return {
      [mapTaxType.preTax]: {
        taxType: mapTaxType.preTax,
        deferralType: values.contribMethod,
        value: preTaxValue[values.contribMethod] - catchupPreTaxValue[values.contribMethod],
      },

      [mapTaxType.roth]: props.store.institutional.rothContribAllowed
        ? {
            taxType: mapTaxType.roth,
            deferralType: values.contribMethod,
            value: rothValue[values.contribMethod] - catchupRothValue[values.contribMethod],
          }
        : undefined,

      [mapTaxType.afterTax]: props.store.institutional.posttaxContribAllowed
        ? {
            taxType: mapTaxType.afterTax,
            deferralType: values.contribMethod,
            value: afterTaxValue[values.contribMethod] - catchupAfterTaxValue[values.contribMethod],
          }
        : undefined,
    }
  }

  function buildCatchUp(values) {
    const mapTaxType = { preTax: 1, afterTax: 2, roth: 5 }
    const preTaxValue = { P: values.catchupPreTaxRate, D: values.catchupPreTaxAmount }
    const rothValue = { P: values.catchupRothRate, D: values.catchupRothAmount }
    const afterTaxValue = { P: values.catchupAfterTaxRate, D: values.catchupAfterTaxAmount }

    return {
      [mapTaxType.preTax]: {
        taxType: mapTaxType.preTax,
        deferralType: values.contribMethod,
        value: preTaxValue[values.contribMethod],
      },

      [mapTaxType.roth]: props.store.institutional.rothContribAllowed
        ? {
            taxType: mapTaxType.roth,
            deferralType: values.contribMethod,
            value: rothValue[values.contribMethod],
          }
        : undefined,

      [mapTaxType.afterTax]: props.store.institutional.posttaxContribAllowed
        ? {
            taxType: mapTaxType.afterTax,
            deferralType: values.contribMethod,
            value: afterTaxValue[values.contribMethod],
          }
        : undefined,
    }
  }

  async function handleSubmit(values) {
    try {
      const {
        store: { setAcceptedContributionDistribution },
      } = props

      const contributionDistribution = {
        savingsRate: buildSavingsRate(values),
        catchUp: buildCatchUp(values),
      }

      setAcceptedContributionDistribution(contributionDistribution)

      logAcceptSavingsRateChange()

      const {
        history,
        offboarding: { nextRoute },
      } = props

      history.push(`/implement/${nextRoute('contribution')}`)
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  function onSubmit(values) {
    return handleSubmit(values)
  }

  function validate(values) {
    return schema
      .validate(values, {
        abortEarly: false,
        context: { contributionChanged, enableCatchupContributions },
      })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  return (
    <Container>
      <DisplayXLarge>Before we can finalize your plan</DisplayXLarge>

      {contributionChanged && (
        <div>
          <DisplayLarge>Paycheck deduction will change</DisplayLarge>

          <DisplaySmall>
            The amount you contribute to your {config.template.sponsorName} retirement plan account
            will be different if you choose to have us implement your changes.
          </DisplaySmall>
        </div>
      )}

      <ContributionComparison />

      <div
        css={css`
          color: #7a8e96;
          font-size: 12px;
          line-height: 1.5;
          margin: 2rem;
        `}>
        *Contribution amounts shown are annualized and rounded up. The actual contribution total
        will be limited to IRS and plan rule maximums. In {limits.year}, the limit is $
        {numberToDollars(limits.dlr401k, true)}; if age {limits.catchupAge}+ in the calendar year,
        you may add up to an additional ${numberToDollars(limits.catchupLimitAmount, true)} in
        catch-up contributions.
        {ageThisYear >= 60 && ageThisYear < 64 && (
          <div
            css={css`
              color: #7a8e96;
              font-size: 12px;
              line-height: 1.5;
              margin-top: 10px;
            `}>
            Be aware: As you are age 60, 61, 62, or 63 this calendar year, the IRS allows you to
            make an additional catch-up contribution to your company’s retirement plan of up to $
            {(additionalCatchup?.maximumAmount - standardCatchup?.maximumAmount).toLocaleString()}{' '}
            for a total catch-up contribution of up to $
            {additionalCatchup?.maximumAmount?.toLocaleString()}, making the retirement plan maximum
            contribution ${(standardLimit + additionalCatchup?.maximumAmount).toLocaleString()}.
          </div>
        )}
        {ageThisYear >= 64 && (
          <div
            css={css`
              color: #7a8e96;
              font-size: 12px;
              line-height: 1.5;
              margin-top: 10px;
            `}>
            Be aware: You may need to reduce your retirement plan contributions. The IRS permits
            those aged 64+ in the current calendar year to make up to the regular catch-up
            contribution limit only. With the standard contribution limit of $
            {standardLimit?.toLocaleString()}, this means the maximum contribution you can make to
            your company’s retirement plan is $
            {(standardLimit + standardCatchup?.maximumAmount)?.toLocaleString()}.
          </div>
        )}
      </div>

      <Form
        initialValues={{
          contribMethod: props.store.institutional.contribMethod,
          annualSalary: props.store.account.annualSalary,
          preTaxRate: props.store.acceptedCase.primary.preTaxAnnualContributions,
          preTaxAmount: props.store.acceptedCase.primary.preTaxAnnualContribDollars,
          rothRate: props.store.acceptedCase.primary.rothAnnualContributions,
          rothAmount: props.store.acceptedCase.primary.rothAnnualContribDollars,
          afterTaxRate: props.store.acceptedCase.primary.postTaxAnnualContributions,
          afterTaxAmount: props.store.acceptedCase.primary.postTaxAnnualContribDollars,
          catchupContribStatus: null,
          catchupPreTaxRate: 0,
          catchupPreTaxAmount: 0,
          catchupRothRate: 0,
          catchupRothAmount: 0,
          catchupAfterTaxRate: 0,
          catchupAfterTaxAmount: 0,
          terms: false,
        }}
        onSubmit={onSubmit}
        validate={validate}
        subscription={{ touched: true, errors: true, submitting: true, submitError: true }}
        render={({ touched, errors, submitting, submitError, handleSubmit }) => {
          const submittingError = !submitting && submitError
          const termsError = !submitting && touched.terms && errors.terms

          return (
            <form onSubmit={handleSubmit}>
              {enableCatchupContributions && (
                <div
                  css={css`
                    margin: 2rem;
                  `}>
                  <CatchupContributions />
                </div>
              )}

              {contributionChanged && <TermsField />}

              <Layout.ErrorMessage>{submittingError || termsError}</Layout.ErrorMessage>

              <ContributionButtons
                isDisabled={submitting || !!errors.terms}
                handleNext={handleSubmit}
              />
            </form>
          )
        }}
      />
    </Container>
  )
}

export default inject('store', 'offboarding')(observer(Contribution))
